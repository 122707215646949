import {Product} from "@/types";
import {LinkListItem, MatchScoreDataRow, ResultsItem} from "@dsm/dsm-storybook";
import settings from "@/service/settings";
import {defaultQuestions} from "@/service/initial-data";

function mapProductResults(products: Product[]): ResultsItem[] {
  const results: ResultsItem[] = [];

  products.forEach(product => {

    let productUrl = product.url;
    if (!productUrl.startsWith("http")) {
      productUrl = settings.BASE_URL + productUrl
    }

    const materialAdvisorMatch = product.materialadvisor.content;
    const questionAnswerMatch: MatchScoreDataRow[] = [];
    const rows: MatchScoreDataRow[] = [];

    // Match table
    defaultQuestions.forEach(question => {
      const maMatch = materialAdvisorMatch[question.index];
      let yourAnswer = "-";
      let materialProperty = "";
      let match = false;

      if (typeof maMatch !== "undefined") {
        const a = question.answers.find(answer => {
          return answer.value === maMatch.answer;
        });


        if (typeof a !== "undefined") {
          yourAnswer = a.i18nDescription;
        }

        match = maMatch.match === 1;

        if (typeof maMatch.value === "string") {
          materialProperty = maMatch.value;
        } else if (maMatch.value !== null) {
          // Value contains string | object | null
          maMatch.value.forEach(v => {
            if (v !== null) {
              if (materialProperty !== "") {
                materialProperty += ", ";
              }

              if (typeof v === "object") {
                const { name, value, unit } = v;
                materialProperty += name + " " + value + " " + unit;
              } else {
                materialProperty += v;
              }
            }
          });
        } else {
          materialProperty = "-";
        }

        rows.push({
          label: question.i18nShortQuestion,
          your_answer: yourAnswer,
          material_property: materialProperty,
          match: match
        });
      }

      if (!question.refine) {
        questionAnswerMatch.push({
            label: question.i18nXsQuestion,
            your_answer: yourAnswer,
            material_property: materialProperty,
            match: match
        });
      }

    });

    // Downloads
    const downloads: LinkListItem[] = [];
    if (typeof product.documents !== "undefined"
      && typeof product.documents.pdf !== "undefined"
      && typeof product.documents.pdf.content !== "undefined") {
      const documents = product.documents.pdf.content;
      documents.forEach(d => {
        let documentUrl = d.url;
        if (!documentUrl.startsWith("http")) {
          documentUrl = settings.BASE_URL + documentUrl
        }
        downloads.push({
          title: d.name,
          link: documentUrl,
          target: "_blank",
          icon: "faFile"
        });
      });
    }

    // Applications
    const applications: LinkListItem[] = [];
    if (typeof product.apps !== "undefined") {
      const apps = product.apps;
      apps.forEach(a => {
        let appUrl = a.url;

        if (!appUrl.startsWith("http")) {
          appUrl = settings.BASE_URL + appUrl
        }

        applications.push({
          title: a.name,
          link: appUrl,
          target: "_blank",
          icon: ""
        })
      });
    }

    // @ts-ignore
    results.push({
      id: product.id,
      active: false,
      title: product.name,
      matchScore: product.materialadvisor.score,
      url: productUrl,
      polymer: product.abbterm,
      processing: product.proctech,
      special: product.specfeat,
      questionAnswerMatch: questionAnswerMatch,
      matchScoreData: {
        headers: ["Question", "Your answer", "Match"],
        rows: rows
      },
      linkListDownloads: downloads,
      linkListTrackRecords: applications,
      buttonList: [
        {
          title: "Technical inquiry",
          link:
            "iframe://www.envalior.com/en-us/contact-us/technical-request/technical-request-form.html?dsm_product=",
          target: "_blank",
          icon: "faArrowRight"
        },
        {
          title: "Order a sample",
          link:
            "iframe://www.envalior.com/en-us/contact-us/quote-or-sample-request/get-a-sample.html?dsm_grade=",
          target: "_blank",
          icon: "faArrowRight"
        },
        {
          title: "Request a quote",
          link:
            "iframe://www.envalior.com/en-us/contact-us/quote-or-sample-request/request-a-quote.html?dsm_grade=",
          target: "_blank",
          icon: "faArrowRight"
        }
      ]
    });



  });

  return results;
}

export { mapProductResults };
