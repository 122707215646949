import { StepProgress, StepType } from "@dsm/dsm-storybook";

export const defaultPagination = {
  current_page: 1,
  from: 0,
  last_page: 0,
  per_page: 10,
  to: 0,
  total: 0
};

export const defaultAnswers = () => [
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null
];

export const defaultQuestions = [
  {
    index: 1,
    i18nQuestion: "questions.temperature.title",
    i18nShortQuestion: "questions.temperature.titleShort",
    i18nXsQuestion: "questions.temperature.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.temperature.yes_continuously",
        i18nDescriptionText: "questions.temperature.yes_continuously_text",
        imgUrl: "/assets/questions/materials-advisor-q1-a.jpg"
      },
      {
        value: "B",
        i18nDescription: "questions.temperature.yes_peak",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q1-b.jpg"
      },
      {
        value: "C",
        i18nDescription: "questions.temperature.no",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q1-c.jpg"
      }
    ],
    refine: false
  },
  {
    index: 2,
    i18nQuestion: "questions.chemicals.title",
    i18nShortQuestion: "questions.chemicals.titleShort",
    i18nXsQuestion: "questions.chemicals.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.chemicals.yes",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q2-a.jpg"
      },
      {
        value: "B",
        i18nDescription: "questions.chemicals.no",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q2-b.jpg"
      }
    ],
    refine: false
  },
  {
    index: 3,
    i18nQuestion: "questions.uv_or_sunlight.title",
    i18nShortQuestion: "questions.uv_or_sunlight.titleShort",
    i18nXsQuestion: "questions.uv_or_sunlight.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.uv_or_sunlight.yes",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q3-a_v2.jpg"
      },
      {
        value: "B",
        i18nDescription: "questions.uv_or_sunlight.no",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q3-b.jpg"
      }
    ],
    refine: false
  },
  {
    index: 4,
    i18nQuestion: "questions.humidity.title",
    i18nShortQuestion: "questions.humidity.titleShort",
    i18nXsQuestion: "questions.humidity.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.humidity.yes_frequently",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q4-a.jpg"
      },
      {
        value: "B",
        i18nDescription: "questions.humidity.yes_occasionally",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q4-b.jpg"
      },
      {
        value: "C",
        i18nDescription: "questions.humidity.no",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q4-c.jpg"
      }
    ],
    refine: false
  },
  {
    index: 5,
    i18nQuestion: "questions.drinking_water_or_food.title",
    i18nShortQuestion: "questions.drinking_water_or_food.titleShort",
    i18nXsQuestion: "questions.drinking_water_or_food.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.drinking_water_or_food.yes_food",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q5-a.jpg"
      },
      {
        value: "B",
        i18nDescription: "questions.drinking_water_or_food.yes_drinking_water",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q5-b.jpg"
      },
      {
        value: "C",
        i18nDescription: "questions.drinking_water_or_food.no",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q5-c.jpg"
      }
    ],
    refine: false
  },
  {
    index: 6,
    i18nQuestion: "questions.flexibility.title",
    i18nShortQuestion: "questions.flexibility.titleShort",
    i18nXsQuestion: "questions.flexibility.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.flexibility.flexible",
        i18nDescriptionText: "questions.flexibility.flexible_text",
        imgUrl: "/assets/questions/materials-advisor-q6-a_v2.jpg"
      },
      {
        value: "B",
        i18nDescription: "questions.flexibility.hard_with",
        i18nDescriptionText: "questions.flexibility.hard_with_text",
        imgUrl: "/assets/questions/materials-advisor-q6-b_v2.jpg"
      },
      {
        value: "C",
        i18nDescription: "questions.flexibility.hard_without",
        i18nDescriptionText: "questions.flexibility.hard_without_text",
        imgUrl: "/assets/questions/materials-advisor-q6-c_v2.jpg"
      }
    ],
    refine: false
  },
  {
    index: 7,
    i18nQuestion: "questions.flame_retardancy.title",
    i18nShortQuestion: "questions.flame_retardancy.titleShort",
    i18nXsQuestion: "questions.flame_retardancy.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.flame_retardancy.yes",
        i18nDescriptionText: "questions.flame_retardancy.yes_text",
        imgUrl: "/assets/questions/materials-advisor-q7-a_v2.jpg"
      },
      {
        value: "B",
        i18nDescription: "questions.flame_retardancy.no",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q7-b.jpg"
      }
    ],
    refine: false
  },
  {
    index: 8,
    i18nQuestion: "questions.look_and_feel.title",
    i18nShortQuestion: "questions.look_and_feel.titleShort",
    i18nXsQuestion: "questions.look_and_feel.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.look_and_feel.important",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q8-a.jpg"
      },
      {
        value: "B",
        i18nDescription: "questions.look_and_feel.somewhat",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q8-b.jpg"
      },
      {
        value: "C",
        i18nDescription: "questions.look_and_feel.not",
        i18nDescriptionText: "",
        imgUrl: "/assets/questions/materials-advisor-q8-c.jpg"
      }
    ],
    refine: false
  },
  {
    index: 9,
    i18nQuestion: "questions.mechanical_load.title",
    i18nShortQuestion: "questions.mechanical_load.titleShort",
    i18nXsQuestion: "questions.mechanical_load.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.mechanical_load.yes",
        i18nDescriptionText: "",
        imgUrl: "#"
      },
      {
        value: "B",
        i18nDescription: "questions.mechanical_load.no",
        i18nDescriptionText: "",
        imgUrl: "#"
      }
    ],
    refine: true
  },
  {
    index: 10,
    i18nQuestion: "questions.continuous_temperature.title",
    i18nShortQuestion: "questions.continuous_temperature.titleShort",
    i18nXsQuestion: "questions.continuous_temperature.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.continuous_temperature.no",
        i18nDescriptionText: "",
        imgUrl: "#"
      },
      {
        value: "B",
        i18nDescription: "questions.continuous_temperature.150",
        i18nDescriptionText: "",
        imgUrl: "#"
      },
      {
        value: "C",
        i18nDescription: "questions.continuous_temperature.175",
        i18nDescriptionText: "",
        imgUrl: "#"
      },
      {
        value: "D",
        i18nDescription: "questions.continuous_temperature.200",
        i18nDescriptionText: "",
        imgUrl: "#"
      }
    ],
    refine: true
  },
  {
    index: 11,
    i18nQuestion: "questions.friction.title",
    i18nShortQuestion: "questions.friction.titleShort",
    i18nXsQuestion: "questions.friction.titleXs",
    answers: [
      { value: "A", i18nDescription: "questions.friction.yes", i18nDescriptionText: "", imgUrl: "#" },
      { value: "B", i18nDescription: "questions.friction.no", i18nDescriptionText: "", imgUrl: "#" }
    ],
    refine: true
  },
  {
    index: 12,
    i18nQuestion: "questions.continuously_mechanically_loaded.title",
    i18nShortQuestion: "questions.continuously_mechanically_loaded.titleShort",
    i18nXsQuestion: "questions.continuously_mechanically_loaded.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.continuously_mechanically_loaded.yes",
        i18nDescriptionText: "",
        imgUrl: "#"
      },
      {
        value: "B",
        i18nDescription: "questions.continuously_mechanically_loaded.no",
        i18nDescriptionText: "",
        imgUrl: "#"
      }
    ],
    refine: true
  },
  {
    index: 13,
    i18nQuestion: "questions.material_withstand.title",
    i18nShortQuestion: "questions.material_withstand.titleShort",
    i18nXsQuestion: "questions.material_withstand.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.material_withstand.yes",
        i18nDescriptionText: "",
        imgUrl: "#"
      },
      {
        value: "B",
        i18nDescription: "questions.material_withstand.no",
        i18nDescriptionText: "",
        imgUrl: "#"
      }
    ],
    refine: true
  },
  {
    index: 14,
    i18nQuestion: "questions.material_shape.title",
    i18nShortQuestion: "questions.material_shape.titleShort",
    i18nXsQuestion: "questions.material_shape.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.material_shape.yes",
        i18nDescriptionText: "",
        imgUrl: "#"
      },
      {
        value: "B",
        i18nDescription: "questions.material_shape.no",
        i18nDescriptionText: "",
        imgUrl: "#"
      }
    ],
    refine: true
  },
  {
    index: 15,
    i18nQuestion: "questions.material_processed.title",
    i18nShortQuestion: "questions.material_processed.titleShort",
    i18nXsQuestion: "questions.material_processed.titleXs",
    answers: [
      {
        value: "A",
        i18nDescription: "questions.material_processed.molding",
        i18nDescriptionText: "",
        imgUrl: "#"
      },
      {
        value: "B",
        i18nDescription: "questions.material_processed.extrusion",
        i18nDescriptionText: "",
        imgUrl: "#"
      }
    ],
    refine: true
  }
];

export const defaultSteps = () => [
  // {
  //   type: StepType.HOME,
  //   i18nLabel: "progress.welcome",
  //   i18nPath: "progress.home",
  //   icon: "home",
  //   index: 0,
  //   progress: StepProgress.COMPLETED
  // },
  {
    type: StepType.QUESTION,
    i18nLabel: "progress.temperature",
    i18nPath: "progress.question1",
    index: 1,
    progress: StepProgress.ACTIVE
  },
  {
    type: StepType.QUESTION,
    i18nLabel: "progress.chemicals",
    i18nPath: "progress.question2",
    index: 2,
    progress: StepProgress.UNANSWERED
  },
  {
    type: StepType.QUESTION,
    i18nLabel: "progress.uv_or_sunlight",
    i18nPath: "progress.question3",
    index: 3,
    progress: StepProgress.UNANSWERED
  },
  {
    type: StepType.QUESTION,
    i18nLabel: "progress.humidity",
    i18nPath: "progress.question4",
    index: 4,
    progress: StepProgress.UNANSWERED
  },
  {
    type: StepType.QUESTION,
    i18nLabel: "progress.drinking",
    i18nPath: "progress.question5",
    index: 5,
    progress: StepProgress.UNANSWERED
  },
  {
    type: StepType.QUESTION,
    i18nLabel: "progress.flexibility",
    i18nPath: "progress.question6",
    index: 6,
    progress: StepProgress.UNANSWERED
  },
  {
    type: StepType.QUESTION,
    i18nLabel: "progress.flame_retardancy",
    i18nPath: "progress.question7",
    index: 7,
    progress: StepProgress.UNANSWERED
  },
  {
    type: StepType.QUESTION,
    i18nLabel: "progress.look_and_feel",
    i18nPath: "progress.question8",
    index: 8,
    progress: StepProgress.UNANSWERED
  },
  {
    type: StepType.ADVISE,
    i18nLabel: "progress.material_advise",
    i18nPath: "progress.results",
    icon: "results",
    index: 9,
    progress: StepProgress.UNANSWERED
  }
];

export const floatingActionsData = {
  emailQuestion: {
    link:
      "https://www.envalior.com/en-us/contact-us.html",
    target: "_blank"
  },
  locateOffice: {
    link:
      "https://www.envalior.com/en-us/contact-us/locate-our-nearest-office.html",
    target: "_blank"
  }
};

export const noResults = {
  title: "No results",
  description:
    "Based on your selections we have identified no material.<br><br> Adjust your search criteria using the options above, or contact us for more information on the possibilities for your application.",
  buttons: [
    {
      label: "Contact a Material Expert",
      link:
        "https://www.envalior.com/en-us/contact-us.html",
      target: "_blank"
    }
  ]
};

export const refineYourSearch = {
  title: "Do these reports give you the right insights?",
  description:
    "Your current selections identified {0} materials. You can refine your search by specifying your requirements in more on the left.",
    buttons: [
    {
      label: "Contact a Material Expert",
      link:
        "https://www.envalior.com/en-us/contact-us.html",
      target: "_blank"
    }
  ]
};

export const mockResultsData = [
  {
    id: 1,
    active: false,
    matchScore: 90,
    title: "Akulon S223-G6-FC BK00001",
    polymer: "PA66-GF30",
    processing: "Injection Molding",
    special: "30% Glass Reinforced, Food Contact Quality",
    matchScoreData: {
      headers: ["", "Your answer", "Material property"],
      rows: [
        {
          label: "Temperature",
          your_answer: "Above 230 C",
          material_property: "600 C",
          match: true
        },
        {
          label: "Sun light/UV light",
          your_answer: "Yes",
          material_property: "Yes",
          match: true
        },
        {
          label: "Food or drinking water contact",
          your_answer: "No",
          material_property: "Yes",
          match: false
        },
        {
          label: "Flexibulity",
          your_answer: "Flexible like rubber",
          material_property: "1000-2000 MPa",
          match: true
        }
      ]
    },
    linkListDownloads: [
      {
        title: "Property Data (PDS)",
        link: "#",
        target: "_blank",
        icon: "faFile"
      },
      {
        title: "Chemical Resistance Data",
        link: "#",
        target: "_blank",
        icon: "faFile"
      },
      {
        title: "Arnitel Recommendations for Extrusion",
        link: "#",
        target: "_blank",
        icon: "faFile"
      }
    ],
    linkListTrackRecords: [
      {
        title: "Breathable Films for Food Packaging",
        link: "#",
        target: "_blank",
        icon: ""
      },
      {
        title: "Breathable Outdoor clothing",
        link: "#",
        target: "_blank",
        icon: ""
      },
      { title: "Medical Tubes", link: "#", target: "_blank", icon: "" },
      { title: "Medical gowns", link: "#", target: "_blank", icon: "" }
    ],
    buttonList: [
      { title: "Technical inquiry", link: "#", target: "_blank", icon: "faArrowRight" },
      { title: "Order a sample", link: "#", target: "_blank", icon: "faArrowRight" },
      { title: "Request a quote", link: "#", target: "_blank", icon: "faArrowRight" }
    ]
  },
  {
    id: 2,
    active: false,
    matchScore: 70,
    title: "Akulon S223-G6-FC BK00001",
    polymer: "PA66-GF30",
    processing: "Injection Molding",
    special: "30% Glass Reinforced, Food Contact Quality"
  },
  {
    id: 3,
    active: false,
    matchScore: 10,
    title: "Akulon S223-G6-FC BK00001",
    polymer: "PA66-GF30",
    processing: "Injection Molding",
    special: "30% Glass Reinforced, Food Contact Quality"
  },
  {
    id: 4,
    active: false,
    matchScore: 90,
    title: "Akulon S223-G6-FC BK00001",
    polymer: "PA66-GF30",
    processing: "Injection Molding",
    special: "30% Glass Reinforced, Food Contact Quality"
  }
];
