import http from "./http";
import settings from "@/service/settings";

class GeneratePdf {

  generatePdf(cookie: string, results: any) {
    const data = {
      data: results,
      cookie: cookie
    }

    const currentDate = new Date();
    const fileName = 'dsm-material-advisor-results_' + currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDay() + '.pdf';

    return http.post(settings.PDF_URL , {
      data: data
    }, {
      responseType: 'blob'
    })
      .then(response => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        a.remove();
        return response;
      });
  }
}

export default new GeneratePdf()
