import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  Answer,
  CompareItem,
  Question,
  ResultsItem,
  Step,
  StepProgress,
  StepType
} from "@dsm/dsm-storybook";
import {
  defaultAnswers,
  defaultPagination,
  defaultQuestions,
  defaultSteps
} from "@/service/initial-data";
import PlasticsFinderDataService from "@/service/plastics-finder-data-service";
import { AnswerUpdate, Pagination, Product } from "@/types";
import { mapProductResults } from "@/utils/productResultsMapping";

export interface AdviseState {
  answers: Array<Answer | null | string>,
  steps: Step[],
  questions: Question[],
  pagination: Pagination,
  products: Product[],
  compare: Array<CompareItem>,
  score: number
}

@Module({ namespaced: true, preserveState: true })
class AdviseModule extends VuexModule implements AdviseState {
  answers: Array<Answer | null | string> = defaultAnswers();
  steps: Step[] = defaultSteps();
  questions: Question[] = defaultQuestions;
  pagination: Pagination = defaultPagination;
  products: Product[] = [];
  compare: Array<CompareItem> = [];
  score = 80;

  get totalResults() {
    return this.pagination.total;
  }

  get currentStep() {
    const [step] = this.steps.filter(
      step => step.progress === StepProgress.ACTIVE
    );
    return step;
  }

  get activeQuestion() {
    if (this.currentStep !== null) {
      const [question] = this.questions.filter(
        question => question.index === this.currentStep.index
      );
      return question ? question : null;
    } else {
      return null;
    }
  }

  get lastAnsweredStep(): number {
    let answeredIndex = 0;
    this.answers.forEach((answer, index) => {
      if (index > 0 && answer !== null) {
        answeredIndex = index;
      }
    });

    return answeredIndex;
  }

  get paginationResults() {
    return this.pagination;
  }

  get productsResults() {
    return this.products;
  }

  get userAnswers() {
    return this.answers;
  }

  get compareItems() {
    return this.compare;
  }

  /**
   * Set user answer to question
   * @param question
   * @param answer
   */
  @Mutation
  updateAnswer({ question, answer }: AnswerUpdate) {
    this.answers[question.index] = answer;
  }

  /**
   * Action to remove an answer
   * @param question
   */
  @Mutation
  removedAnswer(question: Question) {
    this.answers[question.index] = null;
  }

  /**
   * Action to skip question
   * @param question
   */
  @Mutation
  skipQuestion(question: Question) {
    this.answers[question.index] = "skipped";
  }

  /**
   * Set step we want to transition to
   * @param stepIndex
   */
  @Mutation
  setStep(stepIndex: number) {
    // Find current active step
    const [currentStep] = this.steps.filter(
      step => step.progress === StepProgress.ACTIVE
    );

    // Find next step
    const [nextStep] = this.steps.filter(step => step.index === stepIndex);

    // Get state of answer
    const isUnanswered = this.answers[currentStep.index] === null;
    const isSkipped = this.answers[currentStep.index] === "skipped";

    // Set new progress status for current step
    if (currentStep.type === StepType.HOME) {
      currentStep.progress = StepProgress.COMPLETED;
    } else {
      currentStep.progress = isUnanswered
        ? StepProgress.UNANSWERED
        : isSkipped
        ? StepProgress.SKIPPED
        : StepProgress.COMPLETED;
    }

    // Make next step active;
    nextStep.progress = StepProgress.ACTIVE;
  }

  @Mutation
  setProducts(products: Product[]) {
    this.products = products;
  }

  @Mutation
  setPagination(pagination: Pagination) {
    this.pagination = pagination;
  }

  @Mutation
  setPage(pageNumber: number) {
    this.pagination.current_page = pageNumber;
  }

  @Mutation
  addCompare(compareItem: CompareItem) {
    this.compare.push(compareItem);
  }

  @Mutation
  removeCompare(index: number) {
    this.compare.splice(index, 1);
  }

  @Mutation
  setScore(score: number) {
    this.score = score;
  }

  @Mutation
  resetState() {
    this.answers = defaultAnswers();
    this.steps = defaultSteps();
    this.products = [];
    this.pagination.current_page = 1;
    this.pagination.total = 0;
    this.compare = [];
  }

  @Action
  async getResults() {
    const answers = this.answers
      .reduce((q, answer, index) => {
        if (answer !== null && answer !== StepProgress.SKIPPED) {
          // @ts-ignore
          q.push(`${index}-${answer.value}`);
        }
        return q;
      }, [])
      .join(",");

    const size = 10; //this.pagination.per_page; Data response always returns 1
    const currentPage = this.pagination.current_page;
    const score = this.score;

    const results = await PlasticsFinderDataService.getResults(
      answers,
      size,
      currentPage,
      score
    );
    // Mapping
    const productResults: ResultsItem[] = mapProductResults(results.products);
    // Store
    this.context.commit("setProducts", productResults);
    this.context.commit("setPagination", results.pagination);
  }

}

export default AdviseModule;
