import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";

Vue.use(VueI18n);

/**
 * i18n module
 */
export const i18n = new VueI18n({
  locale: "-", // set locale
  fallbackLocale: "-",
  silentTranslationWarn: true,
  messages: undefined
});

const loadedLanguages: Array<string> = []; // our default language that is preloaded

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  const html = document.querySelector("html");
  if (html) {
    html.setAttribute("lang", lang);
  }
  return lang;
}

export async function loadLanguageAsync(lang: string) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const { data } = await axios.get(`/locales/${lang}.json`);

  i18n.setLocaleMessage(lang, data);
  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}

loadLanguageAsync("en-US");

/**
 * Use for translation in templates
 * @param key
 */
export const translate = (key: string) => {
  if (!key) {
    return "";
  }
  return i18n.t(key);
};

export default i18n;
