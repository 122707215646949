









































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  AdvisorProgressText,
  AdvisorProgressActiveStep,
  AdvisorResultsBar,
  Step,
  AdvisorSpinner,
  StepProgress, StepType, Answer, AdvisorCtaBar
} from "@dsm/dsm-storybook";
import AdvisorResultsBarUpdated from "@/views/components/AdvisorResultsBarUpdated.vue";
import AdviseModule from "@/store/modules/advise";
import {floatingActionsData, refineYourSearch} from "@/service/initial-data";
import GeneratePdf from "@/service/generate-pdf";

const advise = namespace("advise");

@Component({
  components: {
    AdvisorCtaBar,
    AdvisorResultsBar,
    AdvisorResultsBarUpdated,
    AdvisorProgressText,
    AdvisorProgressActiveStep,
    AdvisorSpinner
  }
})
export default class BaseLayout extends Vue {
  @advise.Mutation("resetState")
  resetState!: AdviseModule["resetState"];

  @advise.State("steps")
  steps!: Step[];

  @advise.State("questions")
  questions!: AdviseModule["questions"];

  @advise.Getter("totalResults")
  totalResults!: number;

  @advise.Getter("currentStep")
  currentStep!: Step;

  @advise.Getter("userAnswers")
  userAnswers!: AdviseModule["answers"];

  @advise.Getter("productsResults")
  productsResults!: AdviseModule["products"];

  @advise.Action("getResults")
  adviseGetResults!: AdviseModule["getResults"];

  spinnerVisible= false;

  resultsPageCount = 0;

  ctaBarData = refineYourSearch;

  created() {
    // Reset on step 1
    if (this.currentStep.index === 1) {
      const currentAnswer = this.userAnswers[this.currentStep.index] as Answer;
      if (currentAnswer && currentAnswer.value !== '') {
        this.resetState();
      }
    }
    // Get initial total of products
    this.adviseGetResults();
  }

  updated() {
    this.postParentMessage("calcHeight");
  }

  get hideProgressAndResultsBar() {
    return this.$route.name !== "refine" && this.$route.name !== "results";
  }

  get showOnResults() {
    return this.$route.name === "results";
  }

  get showContactOptions() {
    return this.$route.name === "welcome";
  }

  get showAdvise() {
    if (this.$route.name === "results") {
      this.resultsPageCount++;
      return false;
    }

    return this.totalResults > 0 && this.totalResults < 100;
  }

  get breadcrumbPaths() {
    const paths = [];

    const home = this.steps.find(step => step.type === StepType.HOME);
    if (home !== undefined) {
      paths.push(home);
    }
    const activeStep = this.steps.find(step => step.progress === StepProgress.ACTIVE);
    if (activeStep !== undefined) {
      paths.push(activeStep);

      if (activeStep.type === StepType.ADVISE && !this.hideProgressAndResultsBar) {
        const newStep = {
          i18nPath: "Refine"
        };
        paths.push(newStep);
      }
    }
    return paths;
  }

  get ctaDataDescription() {
    let desc = this.ctaBarData.description;
    let label = this.$t("label.materials")
    if (this.totalResults === 1) {
      label = this.$t("label.material");
    }
    const totalResults = this.totalResults.toString();
    label = label.toString().toLowerCase();
    desc = desc.replace("{0}", totalResults + " " + label);

    return desc;
  }

  postParentMessage(action:string) {
    switch (action) {
      case "calcHeight": {
        // If MA is used as iFrame the automatically determine and set the height off the iFrame
        const maApp = document.getElementById("material-advisor");
        if (maApp !== null) {
          window.parent.postMessage(["maSetHeight", maApp.offsetHeight + 20], "*");
        }
        break;
      }
      case "resetScroll": {
        window.parent.postMessage(["maResetScroll"], "*");
        break;
      }
    }
  }

  toResultsPage() {
    this.$router.push({
      name: "results"
    });
  }

  /**
   * Goto user selected step
   */
  onStep(step: Step) {
    this.setStep(step.index);
    this.postParentMessage("resetScroll");
  }

  /**
   * Goto previous step
   */
  onPrevious() {
    this.setStep(this.currentStep.index - 1);
    this.postParentMessage("resetScroll");
  }

  /**
   * Goto next step
   */
  onNext() {
    this.setStep(this.currentStep.index + 1);
    this.postParentMessage("resetScroll");
  }

  /**
   * Change url to applicable path
   * @param stepIndex
   */
  setStep(stepIndex: number) {
    // Reset on step 1
    // console.log('setStep');
    // if (stepIndex === 1) {
    //   if ((this.userAnswers[stepIndex] as Answer).value !== '') {
    //     this.resetState();
    //   }
    // }

    // always get new results
    this.adviseGetResults();
    if (stepIndex === 0) {
      this.$router.push({
        name: "welcome"
      });
    } else if (stepIndex === this.steps.length) {
      this.$router.push({
        name: "results"
      });
    } else {
      this.$router.push({
        name: "question",
        params: { index: stepIndex.toString() }
      });
    }
  }

  emailSupport() {
    window.open(
      floatingActionsData.emailQuestion.link,
      floatingActionsData.emailQuestion.target
    );
  }

  locateOffice() {
    window.open(
      floatingActionsData.locateOffice.link,
      floatingActionsData.locateOffice.target
    );
  }

  emailResults() {
    console.log("email results");
  }

  downloadPdf() {
    let appServiceAuthSession = "";
    const cookieData = this.$cookies.get("AppServiceAuthSession");
    if (cookieData !== null) {
      appServiceAuthSession = cookieData;
    }

    const results = {
      adviseQuestions: this.questions,
      adviseTotalResults: this.totalResults,
      adviseResults: this.productsResults,
      adviseUserAnswers: this.userAnswers
    };

    // Start loading
    this.spinnerVisible = !this.spinnerVisible;
    GeneratePdf.generatePdf(appServiceAuthSession, results)
    .then(data => {
      // stop loading
      this.spinnerVisible = !this.spinnerVisible;
    }).catch(err => {
      // stop loading
      this.spinnerVisible = !this.spinnerVisible;
      console.log(err);
    });
  }

  showSpinner(show: boolean) {
    this.spinnerVisible = show ? true : false;
  }

  routerLinkCTA(routerName: string) {
    this.$router.push(routerName);
  }
}
