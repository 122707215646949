















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AdvisorResultsBarUpdated extends Vue {
  @Prop({ default: true })
  visible!: boolean;

  @Prop({ default: 0 })
  totalResults!: number;

  @Prop({ default: false })
  adviseActive!: boolean;

  @Prop({ default: () => [49, 99, 299], type: Array })
  statusMap!: string[];

  get resultsStatusClass() {
    let resultsStatus = "status";

    if (this.totalResults === 0) {
      return (resultsStatus += "-0");
    }

    if (
      this.totalResults <= parseInt(this.statusMap[0]) &&
      this.totalResults > 0
    ) {
      return (resultsStatus += "-xs");
    }

    if (
      this.totalResults <= parseInt(this.statusMap[1]) &&
      this.totalResults > 0
    ) {
      return (resultsStatus += "-sm");
    }

    if (
      this.totalResults <= parseInt(this.statusMap[2]) &&
      this.totalResults > 0
    ) {
      return (resultsStatus += "-md");
    }

    return (resultsStatus += "-lg");
  }
}
