import http from "./http";
import settings from "@/service/settings";
import { Results } from "@/types";

class PlasticsFinderDataService {
  /**
   * List of grades with score and answers to questions.
   */
  async getResults(q: string, size: number, currentPage: number, score: number) {
    const params: { [key: string]: string } = {
      api_token: settings.API_TOKEN
    };

    if (q) {
      params.q = q;
    }

    // Pagination params
    params.per_page = size.toString();
    params.current_page = currentPage.toString();
    params.score = score.toString();

    const greatGrade = 1;
    params.greatgrade = greatGrade.toString();

    const { data } = await http.get(`/v1/magrades`, {
      params
    });

    return data as Results;
  }
}

export default new PlasticsFinderDataService();
