import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import BaseLayout from "@/views/layout/Base.vue";
import PdfLayout from "@/views/layout/Pdf.vue";
import { loadLanguageAsync } from "../i18n";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/:lang",
    component: BaseLayout,
    meta: {
      title: 'Material Advisor | Envalior Engineering Materials',
    },
    children: [
      {
        path: "",
        redirect: "question/1",
        name: "welcome"
        // component: () =>
        //   import(/* webpackChunkName: "common" */ "@/views/Welcome.vue")
      },
      {
        path: "question/:index",
        name: "question",
        props: (to: Route) => ({
          index: parseInt(to.params.index)
        }),
        component: () =>
          import(/* webpackChunkName: "common" */ "@/views/Question.vue")
      },
      {
        path: "results",
        name: "results",
        component: () =>
          import(/* webpackChunkName: "common" */ "@/views/Results.vue")
      },
      {
        path: "results/:qAnswers",
        name: "results",
        props: (to: Route) => ({
          qAnswers: to.params.qAnswers
        }),
        component: () =>
          import(/* webpackChunkName: "common" */ "@/views/Results.vue")
      },
      {
        path: "refine",
        name: "refine",
        component: () =>
          import(/* webpackChunkName: "common" */ "@/views/Refine.vue")
      }
    ],
  },
  {
    path: "/:lang/pdf-download",
    name: "pdfDownload",
    component: PdfLayout
  },
  {
    path: "*",
    redirect: "/en-US"
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if(nearestWithTitle) { document.title = nearestWithTitle.meta.title; }
  const lang = to.params.lang;
  loadLanguageAsync(lang).then(() => {
    next();
  });
});

export default router;
