import Vue from "vue";
import store from "@/store";
import router from "@/router";
import i18n from "@/i18n";
import { ComponentLibrary } from "@dsm/dsm-storybook";
import MaterialAdvisor from "@/MaterialAdvisor.vue";
import VModal from "vue-js-modal";
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false;
Vue.config.silent = true;

Vue.use(ComponentLibrary, {
  i18n
});
Vue.use(VModal);
Vue.use(VueCookies);

new Vue({
  i18n,
  router,
  store,
  render: h => h(MaterialAdvisor)
}).$mount("#material-advisor");
