import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import advise, {AdviseState} from "@/store/modules/advise";

Vue.use(Vuex);

export interface State {
  advise: AdviseState
}

const vuexLocal = new VuexPersistence<State>({
  storage: window.sessionStorage,
  reducer: (state) => {
    return {
      advise: {
        answers: state.advise.answers,
        steps: state.advise.steps,
        pagination: state.advise.pagination,
        products: state.advise.products,
        compare: state.advise.compare
      }
    }
  }
})

const store = new Vuex.Store({
  modules: {
    advise
  },
  plugins: [vuexLocal.plugin]
});
export default store;
