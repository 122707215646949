








































import { Component, Vue } from "vue-property-decorator";
import {Question} from "@dsm/dsm-storybook";
import AdvisorIcon from "@dsm/dsm-storybook/src/components/advisor-icon/AdvisorIcon.vue";

@Component({
  components: {
    AdvisorIcon
  }
})
export default class PdfLayout extends Vue {
  pdfSolution!: {
    cookie: "",
    data: ""
  }
  data:any = {
    adviseTotalResults: 0,
    adviseQuestions: [],
    adviseResults: [],
    adviseUserAnswers: []
  }

  created() {
    const data = localStorage.getItem("pdfPayload");
    if (data !== null) {
      this.pdfSolution = JSON.parse(data);
      this.data = this.pdfSolution.data;
    }
  }

  get mainQuestions() {
    const questions: Question[] = [];
    this.data.adviseQuestions.forEach((question: Question) => {
      if (question.refine === false) {
        questions.push(question);
      }
    });
    return questions;
  }


  getAnswer(index: number) {
    const answer = this.data.adviseUserAnswers[index];
    if (answer !== null) {
      return answer.i18nDescription;
    }
    return "-";
  }
}
